import { useEffect, useRef } from 'react'

type Props = {
  children: string
}

export default function CodeBlock({ children }: Props) {
  const ref = useRef(null)
  useEffect(() => {
    import('components/prism/prism').then(() => {
      ;(window as any).Prism.highlightAllUnder(ref.current)
    })
  }, [children])
  return (
    <pre ref={ref}>
      <code className="language-tsx">{children}</code>
    </pre>
  )
}
