import UsageExample from 'components/UsageExample'

const REACT_EXAMPLE = `// React
import { createDashingKitClient } from '@dashing-kit/client'
import DashingKitProvider from '@dashing-kit/react/DashingKitProvider'
import DashingActivity from '@dashing-kit/react/DashingActivity'

const MyComponent = () => {
  const client = useMemo(() => 
    createDashingKitClient({
      user: { id: 'foo', name: 'Grace Hopper' },
      projectId: 'prj_xxx',
      accessToken: 'client_xxx'
    }), [])

  return (
    <DashingKitProvider client={client}>
      <div>
        <h1>Order #123</h1>
      </div>
      <DashingActivity entityId="order_123" />
    </DashingKitProvider>
  )
}`

const PLAIN_EXAMPLE = `// Plain JS
<script src="//unpkg.com/@dashing-kit/plain" />

<div>
  <h1>Order #123</h1>
  <div id="order-123-activity"></div>
</div>

<script type="application/javascript">
  const client = DashingKit.createDashingKitClient({
    user: { id: 'foo', name: 'Grace Hopper' },
    projectId: 'prj_xxx',
    accessToken: 'client_xxx'
  })
  const target = document.getElementById('order-123-activity')
  DashingKit.DashingActivity.render(target, {
    entityId: 'order_123'
  })
</script>`

type Option = {
  title: string
  code: string
  value: string
}

const OPTIONS: Option[] = [
  {
    title: 'React',
    code: REACT_EXAMPLE,
    value: 'react'
  },
  {
    title: 'Plain JS',
    code: PLAIN_EXAMPLE,
    value: 'plain'
  }
]

export default function GettingStartedExample() {
  return <UsageExample options={OPTIONS} />
}
