import CodeBlock from 'components/CodeBlock'
import { useState } from 'react'

type Option = {
  title: string
  code: string
  value: string
}

type Props = {
  options: Option[]
}

export default function UsageExample({ options }: Props) {
  const [selectedOption, setSelectedOption] = useState<string>(options[0].value)
  return (
    <div>
      <div className="flex justify-start flex-row">
        <select
          className="mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
          onChange={(e) => setSelectedOption(e.target.value)}
          value={selectedOption}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.title}
            </option>
          ))}
        </select>
      </div>
      <div>
        <CodeBlock>
          {options.find((o) => o.value === selectedOption)!.code}
        </CodeBlock>
      </div>
    </div>
  )
}
