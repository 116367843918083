import React from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  children:
    | React.ReactElement
    | string
    | React.ReactChild
    | React.ReactChild[]
    | React.ReactChildren
  Component?: string
} & (
  | Partial<React.AnchorHTMLAttributes<any>>
  | Partial<React.BaseHTMLAttributes<any>>
)

export default function GradientText({
  children,
  className,
  Component = 'span',
  ...rest
}: Props) {
  return React.createElement(
    Component,
    {
      className: classNames(
        'bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-purple-400',
        className
      ),
      ...rest
    },
    children
  )
}
