import { withApollo } from 'components/withApollo'
import {
  CloudUploadIcon,
  AnnotationIcon,
  CogIcon,
  PuzzleIcon,
  LightningBoltIcon,
  ShieldCheckIcon
} from '@heroicons/react/outline'
import PublicLayout from 'components/public/PublicLayout'
import Image from 'next/image'
import Link from 'next/link'
import GradientText from 'components/GradientText'

import headerImage from './header-image.svg'
import ctaImage from './man-programming-unsplash.jpg'
import GettingStartedExample from './GettingStartedExample'

const features = [
  {
    name: 'Comments',
    description: 'Add comments to anything',
    icon: AnnotationIcon
  },
  {
    name: 'Attachments',
    description: 'Upload attachments with previews',
    icon: CloudUploadIcon
  },
  {
    name: 'Custom events',
    description: 'Use our API to add any events to activity log',
    icon: PuzzleIcon
  },
  {
    name: 'Security',
    description: 'We make it easy to add customer permissions',
    icon: ShieldCheckIcon
  },
  {
    name: 'API',
    description:
      'We provide an API if you want to load / add comments programmatically',
    icon: CogIcon
  },
  {
    name: 'Live Updates',
    description:
      'See new comments as they come without the need to refresh a page',
    icon: LightningBoltIcon
  }
]

const HomePage = () => {
  return (
    <PublicLayout isStatic>
      <div className="pt-4 pb-2 bg-gray-900 sm:pt-12 lg:pt-24 lg:pb-16">
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center pb-10 lg:pb-0">
              <div className="lg:pt-0 lg:pb-24">
                <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                  <GradientText className="block">Comments widget</GradientText>
                  <span className="block pb-3  sm:pb-5">for anything</span>
                </h1>
                <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                  Don&apos;t spend time building your own. Easily integrate to
                  get{' '}
                  <span className="font-extrabold text-white">
                    <GradientText> comments</GradientText>
                  </span>
                  {', '}
                  <span className="font-extrabold text-white">
                    <GradientText>file attachments</GradientText>
                  </span>{' '}
                  and{' '}
                  <span className="font-extrabold text-white">
                    <GradientText>live updates</GradientText>
                  </span>
                </p>
                <div className="mt-10 sm:mt-12">
                  <div className="sm:max-w-xl sm:mx-auto lg:mx-0">
                    <div className="sm:flex justify-center lg:justify-start">
                      <div className="rounded-md shadow">
                        <Link href="/signup" passHref>
                          <a className="w-full flex items-center justify-center px-8 py-3 rounded-md shadow bg-gradient-to-r from-pink-500 to-purple-600 text-white font-medium hover:from-pink-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-400 focus:ring-offset-gray-900 md:py-4 md:text-lg md:px-10">
                            Try for free
                          </a>
                        </Link>
                      </div>
                      <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                        <a
                          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200 md:py-4 md:text-lg md:px-10"
                          href="#"
                        >
                          Live demo
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 lg:m-0 lg:relative hidden lg:block">
              <Image
                alt="Dashboard sketch"
                className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                layout="fill"
                src={headerImage}
                priority
              />
            </div>
          </div>
        </div>
      </div>

      {/* Feature section with screenshot */}
      <div className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32 pb-20">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <h2 className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              Easy to get started
            </h2>
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              It takes only <span className="font-bold">7</span> minutes to set
              up an account and include our code snippet into your dashboard.
            </p>
          </div>
          <div className="flex justify-center items-center mt-10">
            <div className="lg:w-1/2 overflow-x-auto">
              <GettingStartedExample />
            </div>
          </div>
        </div>
      </div>

      {/* Feature section with grid */}
      <div className="relative bg-white py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">
            Feature packed
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Easy start, flexible customization
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            We make it easy to get started, and meet your other requirements if
            you need it
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-pink-500 to-purple-600 rounded-md shadow-lg">
                          <feature.icon
                            aria-hidden="true"
                            className="h-6 w-6 text-white"
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="relative bg-gray-900">
        <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <Image
            alt="Man programming"
            className="w-full h-full object-cover"
            layout="fill"
            src={ctaImage}
          />
          <div
            aria-hidden="true"
            className="absolute inset-0 bg-gradient-to-r from-pink-200 to-purple-200 mix-blend-multiply"
          />
        </div>
        <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
          <div className="md:ml-auto md:w-1/2 md:pl-10">
            <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
              Get things done
            </h2>
            <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
              Productivity obsessed
            </p>
            <p className="mt-3 text-lg text-gray-300">
              Like you, we cut to the chase. Having built many dashboards we see
              that components have to be written over and over. We aim to solve
              that.
            </p>
            <p className="mt-3 text-lg text-gray-300">
              Focus on your business by leaving other things to us.
            </p>
            <div className="mt-8">
              <div className="sm:max-w-xl sm:mx-auto lg:mx-0">
                <div className="sm:flex justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <Link href="/signup" passHref>
                      <a className="w-full flex items-center justify-center px-8 py-3 rounded-md shadow bg-gradient-to-r from-pink-500 to-purple-600 text-white font-medium hover:from-pink-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900 md:py-4 md:text-lg md:px-10">
                        Try for free
                      </a>
                    </Link>
                  </div>
                  <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                    <a
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200 md:py-4 md:text-lg md:px-10"
                      href="#"
                    >
                      Live demo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PublicLayout>
  )
}

export default withApollo({ ssr: false })(HomePage)
